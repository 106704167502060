import "./Pricing.css";
import React, { useState } from 'react';
import { FaRocket, FaGem, FaPuzzlePiece, FaCheckCircle } from 'react-icons/fa';
import { db } from '../Firebase';
import { doc, setDoc } from 'firebase/firestore';

const Pricing = ({ user, navigateTo }) => {
    const [clickedPlan, setClickedPlan] = useState(null);
    const [, setConfirmation] = useState(false);

    const handleTabClick = async (plan) => {
        // if (!user) {
        //     console.error('User is not authenticated');
        //     return;
        // }
        setClickedPlan(plan);  // Set the clicked plan
    };

    const handleWaitlistResponse = async (response) => {
        if (response) {
            try {
                if (!user) {
                    navigateTo('account');
                }
                const userDocRef = doc(db, 'users', user.uid);
                await setDoc(userDocRef, {
                    userId: user.uid,
                    email: user.email,
                    selectedPlan: clickedPlan,
                    addedAt: new Date(),
                });
                alert("You've been added to the pre-order waitlist!");
                setConfirmation(true);
            } catch (error) {
                console.error('Error adding to waitlist: ', error);
            }
        } else {
            navigateTo('home');
            setConfirmation(false);  // Reset the confirmation
        }
    };

    const renderCardContent = (plan) => {
        if (clickedPlan === plan) {
            return (
                <div className="waitlist-message">
                    <p>
                        We're excited about your interest, but as we're in pre-release, would you like to join our pre-order waitlist?
                    </p>
                    <div className="waitlist-buttons">
                        <button onClick={() => handleWaitlistResponse(true)} className="waitlist-button">
                            Yes, add me to the waitlist
                        </button>
                        <button onClick={() => handleWaitlistResponse(false)} className="waitlist-button">
                            No, thanks
                        </button>
                    </div>
                </div>
            );
        } else {
            return (
                <>
                    <header>
                        <FaRocket className="pricing-icon" aria-label="Basic Plan Icon" />
                        <h3>Plan A</h3>
                    </header>
                    <div className="price-container">
                        <strong className="price">$9.99</strong>
                        <span>/month</span>
                    </div>
                    <ul className="pricing-features">
                        <li><FaCheckCircle className="feature-icon" /> Upload up to <strong>5 documents</strong></li>
                        <li><FaCheckCircle className="feature-icon" /> API rate limit: <strong>50 messages/hour</strong></li>
                    </ul>
                </>
            );
        }
    };

    return (
        <div className="pricing-container">
            <h1 className="pricing-title">Pricing Plans</h1>

            <section className="pricing-section">
                <h2 className="pricing-subtitle">Choose Your Plan</h2>
                <p className="pricing-description">
                    Select the plan that best fits your needs — straightforward options with no hidden fees!
                </p>
                <p className="pricing-trial">
                    <strong>
                        Commitment issues? Try it out with a 14-day free trial! (No credit card required)
                    </strong>
                </p>
                <div className="pricing-cards">
                    {/* Basic Plan */}
                    <article className="pricing-card basic-plan" onClick={() => handleTabClick('Plan A')}>
                        {renderCardContent('Plan A')}
                    </article>

                    {/* Premium Plan */}
                    <article className="pricing-card premium-plan" onClick={() => handleTabClick('Plan B')}>
                        {clickedPlan === 'Plan B' ? (
                            <div className="waitlist-message">
                                <p>
                                    We're excited about your interest, but as we're in pre-release, would you like to join our pre-order waitlist?
                                </p>
                                <div className="waitlist-buttons">
                                    <button onClick={() => handleWaitlistResponse(true)} className="waitlist-button">
                                        Yes, add me to the waitlist
                                    </button>
                                    <button onClick={() => handleWaitlistResponse(false)} className="waitlist-button">
                                        No, thanks
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <>
                                <header>
                                    <FaGem className="pricing-icon" aria-label="Premium Plan Icon" />
                                    <h3>Plan B</h3>
                                </header>
                                <div className="price-container">
                                    <strong className="price">$39.99</strong>
                                    <span>/month</span>
                                </div>
                                <ul className="pricing-features">
                                    <li><FaCheckCircle className="feature-icon" /> Upload up to <strong>100 documents</strong></li>
                                    <li><FaCheckCircle className="feature-icon" /> API rate limit: <strong>250 messages/hour</strong></li>
                                    <li><FaCheckCircle className="feature-icon" /> <strong>Better LLM Models</strong></li>
                                </ul>
                            </>
                        )}
                    </article>

                    {/* Custom Integration Plan */}
                    <article className="pricing-card custom-plan" onClick={() => handleTabClick('Custom Plan')}>
                        {clickedPlan === 'Custom Plan' ? (
                            <div className="waitlist-message">
                                <p>
                                    We're excited about your interest, but as we're in pre-release, would you like to join our pre-order waitlist?
                                </p>
                                <div className="waitlist-buttons">
                                    <button onClick={() => handleWaitlistResponse(true)} className="waitlist-button">
                                        Yes, add me to the waitlist
                                    </button>
                                    <button onClick={() => handleWaitlistResponse(false)} className="waitlist-button">
                                        No, thanks
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <>
                                <header>
                                    <FaPuzzlePiece className="pricing-icon" aria-label="Custom Plan Icon" />
                                    <h3>Custom Plan</h3>
                                </header>
                                <div className="price-container">
                                    <strong className="price">Contact Us</strong>
                                </div>
                                <ul className="pricing-features">
                                    <li><FaCheckCircle className="feature-icon" /> Tailored solutions for your business needs</li>
                                    <li><FaCheckCircle className="feature-icon" /> Dedicated support</li>
                                    <li><FaCheckCircle className="feature-icon" /> Flexible pricing options</li>
                                </ul>
                            </>
                        )}
                    </article>
                </div>
            </section>
        </div>
    );
};

export default Pricing;
