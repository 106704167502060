import React, { useState } from "react";
import {
    signInWithEmailAndPassword,
    GoogleAuthProvider,
    signInWithPopup,
} from "firebase/auth";
import { auth } from "../Firebase"; // Import Firebase auth instance
import "./Login.css"; // Create this CSS file with styles to match your theme

const Login = ({ navigateTo }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError("");

        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Check if email is verified
            if (!user.emailVerified) {
                setError("Your email is not verified. Please check your inbox for a verification link.");
                await auth.signOut(); // Optionally sign out the user until they verify
                navigateTo("verify"); // Navigate to verify page
                setLoading(false);
                return;
            }

            // Handle successful login (e.g., redirect to dashboard)
            // navigateTo('dashboard');
        } catch (error) {
            switch (error.code) {
                case "auth/invalid-credential":
                    setError("Incorrect username or password. Please try again.");
                    break;
                default:
                    setError("An unexpected error occurred. Please try again later.");
                    break;
            }
        } finally {
            setLoading(false);
        }
    };

    const handleGoogleLogin = async () => {
        const provider = new GoogleAuthProvider();
        try {
            const userCredential = await signInWithPopup(auth, provider);
            const user = userCredential.user;

            // Check if email is verified (Google accounts are generally already verified)
            if (!user.emailVerified) {
                setError("Your email is not verified. Please check your inbox for a verification link.");
                await auth.signOut();
                navigateTo("verify"); // Navigate to verify page
                return;
            }

            // Handle successful login with Google
            // navigateTo('dashboard');
        } catch (error) {
            setError("An error occurred during Google login. Please try again later.");
        }
    };

    const handleFacebookLogin = async () => {
        // Implement Facebook login using signInWithPopup
    }

    return (
        <div className="login-container">
            <h2 className="login-title">Login</h2>
            <form onSubmit={handleLogin} className="login-form">
                <input
                    type="email"
                    className="login-input"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    required
                />
                <input
                    type="password"
                    className="login-input"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    required
                />
                {error && <p className="login-error">{error}</p>}
                <button type="submit" className="login-button" disabled={loading}>
                    {loading ? "Logging in..." : "Login"}
                </button>
            </form>
            <p className="login-create-account">
                Don't have an account?{" "}
                <span
                    onClick={() => navigateTo("signup")}
                    style={{
                        cursor: "pointer",
                        color: "var(--accent-color)",
                        textDecoration: "underline",
                    }}
                >
                    Create Account
                </span>
            </p>
            <div className="login-divider">
                <button onClick={handleGoogleLogin} className="google-login-button">
                    <img src="/google-logo.png" alt="Google" />
                    <span>Continue with Google</span>{" "}
                </button>
                <button onClick={handleFacebookLogin} className="facebook-login-button">
                    <img src="/Facebook_Logo_Secondary.png" alt="Facebook" />
                    <span>Continue with Facebook</span>{" "}
                </button>
            </div>
        </div>
    );
};

export default Login;