import React, { useState } from 'react';
import { useAuth } from '../../Auth';  // Import the custom hook to access the user
import { signOut } from 'firebase/auth';
import { auth } from '../Firebase';  // Firebase auth instance
import './Account.css';  // Import styling for the account page
import Personal from './Personal';  // Import the Personal component
import Billing from './Billing';    // Import the Billing component
import BotInstances from './BotInstances';  // Import the BotInstances component
import AccountSettings from './AccountSettings';

const Account = ({authToken}) => {
    const { user } = useAuth();  // Access the logged-in user
    const [selectedSection, setSelectedSection] = useState('personal');  // State to track selected tab

    const handleLogout = async () => {
        try {
            await signOut(auth);
            // Redirect or update UI after logout
        } catch (error) {
            console.log(error.message);
        }
    };

    // Function to render the selected section
    const renderSection = () => {
        switch (selectedSection) {
            case 'personal':
                return <Personal user={user} />;
            case 'botInstances':
                return <BotInstances user={user} />;
            case 'billing':
                return <Billing user={user} />;
            case 'accountSettings':
                return <AccountSettings user={user} handleLogout={handleLogout} auth={authToken} />;
            default:
                return null;
        }
    };

    return (
        <div className="account-container">
            <div className="account-topmenu">
                <ul>
                    <li
                        className={selectedSection === 'personal' ? 'active' : ''}
                        onClick={() => setSelectedSection('personal')}
                    >
                        Personal Information
                    </li>
                    <li
                        className={selectedSection === 'botInstances' ? 'active' : ''}
                        onClick={() => setSelectedSection('botInstances')}
                    >
                        Bot Instances
                    </li>
                    <li
                        className={selectedSection === 'billing' ? 'active' : ''}
                        onClick={() => setSelectedSection('billing')}
                    >
                        Billing & Payments
                    </li>
                    <li
                        className={selectedSection === 'accountSettings' ? 'active' : ''}
                        onClick={() => setSelectedSection('accountSettings')}
                    >
                        Account Settings
                    </li>
                </ul>
            </div>
            <div className="account-content">
                {renderSection()}
            </div>
        </div>
    );
};

export default Account;
