import "./Verify.css";
import React from "react";

const Verify = ({ navigateTo }) => {
    return (
        <div className="verify-container">
            <h2 className="verify-title">Verify Email</h2>
            <p className="verify-text">
                We have sent a verification email to your inbox. Please click the link in the email to verify your account.
            </p>
            <button className="verify-login-button" onClick={() => navigateTo('account')}>
                Go to Login
            </button>
        </div>
    );
}

export default Verify;