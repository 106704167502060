// src/components/Builder/Builder.js

import "./Builder.css";
import React, { useState } from "react";
// import { v4 as uuidv4 } from 'uuid';
import ChatbotForm from './ChatbotForm';
import Loading from "./Loading";
import Tuner from "../Tuner";

const Builder = ({ cached, authToken, setPrivateToken, user }) => {
    const [built, setBuilt] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [chatbotConfig, setChatbotConfig] = useState({
        company: '',
        businessDescription: '',
        chatbotType: '',
        chatbotTone: '',
        chatbotName: '',
        websiteUrl: '',
        crawlAllowed: false,
        fallBackBehaviour: '',
    });
    const [pdfFiles, setPdfFiles] = useState([]);
    const [useToken, setUseToken] = useState(authToken);

    // Handler for form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setChatbotConfig(prevConfig => ({
            ...prevConfig,
            [name]: value
        }));
    };

    // Handler for PDF file changes
    const handlePdfChange = (event) => {
        setPdfFiles([...pdfFiles, ...event.target.files]);
    };

    // Handler for deleting a PDF file from the list
    const handlePdfDelete = (index) => {
        const updatedFiles = pdfFiles.filter((_, i) => i !== index);
        setPdfFiles(updatedFiles);
    };

    // Existing uploadedFile function
    async function uploadedFile(authToken, formData, chatId, controller) {
        const response = await fetch(`/api/v1/upload`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${useToken}`,
            },
            body: formData,
            // signal: controller.signal,
        });
        if (response.ok) {
            return { ok: true, message: "File uploaded successfully." };
        }
        return { ok: false, message: response.statusText };
    }

    // Handler for form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!chatbotConfig.crawlAllowed) {
            chatbotConfig.websiteUrl = '';
        }

        // Send chatbotConfig to backend
        try {
            setProcessing(true);
            const response = await fetch('/api/v1/static-build/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify(chatbotConfig)
            });

            setProcessing(false);
            if (response.ok) {
                const data = await response.json();
                setPrivateToken(data.token);
                setUseToken(data.token);

                setProcessing(true);
                if (pdfFiles.length > 0) {
                    console.log('Uploading files:', pdfFiles);
                    for (let i = 0; i < pdfFiles.length; i++) {
                        const formData = new FormData();
                        formData.append('file', pdfFiles[i]);
                        const uploadResponse = await uploadedFile(authToken, formData);
                        if (!uploadResponse.ok) {
                            console.error('Error uploading file:', uploadResponse.message);
                            alert('There was an error uploading your file. Please try again.');
                            setProcessing(false);
                            return;
                        }
                    }
                }
                setProcessing(false);
                // alert('Chatbot created successfully.');
                setBuilt(true);
            } else {
                // Handle errors
                const errorData = await response.json();
                console.error('Error creating chatbot:', errorData.message);
                alert('There was an error creating your chatbot. Please try again.');
            }
        } catch (error) {
            console.error('Network error:', error);
            alert('A network error occurred. Please check your connection and try again.');
        }
    };

    return (
        <div className="Builder">
            {
                processing ? <Loading user={user} /> : 
                built ? 
                <Tuner cached={cached} authToken={useToken} /> :
                <ChatbotForm
                    chatbotConfig={chatbotConfig}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    pdfFiles={pdfFiles}
                    handlePdfChange={handlePdfChange}
                    handlePdfDelete={handlePdfDelete}
                />
            }
        </div>
    );
};

export default Builder;
