import "./Counter.css";
import React from "react";

const Counter = ({ count }) => {
    const maxLimit = 10;

    const progressPercentage = (count / maxLimit) * 100;

    return (
        <div className="counter-container">
            <div className="progress-bar-container">
                <div
                    className="progress-bar-fill"
                    style={{ width: `${progressPercentage}%` }}
                ></div>
            </div>
        </div>
    );
};

export default Counter;