import "./Tuner.css";
import React, { useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import Counter from './Counter';
import Chat from '../Chat';

const GREET = "Welcome to the bot tuner! Here you can test your bot and see how well it performs. Ready to start?";

const Tuner = ({ cached, authToken }) => {
    const [chatId, setChatId] = useState(cached.id ? cached.id : uuidv4());
    const [counter, setCounter] = useState(0);

    async function fetchResponse(authToken, userInput, chatId, controller, score) {
        const response = await fetch("/api/v1/tune/" + chatId, {
            method: "POST",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: `Bearer ${authToken}`,
            },
            body: new URLSearchParams({
                inputs: userInput,
                score: score,
            }),
            signal: controller.signal,
        });
        if (response.ok) {
            const data = await response.json();
            if (data.done) {
                setChatId(uuidv4());
                return "Thank you for trying out the bot builder. Since this feature is still in early development, I will not be able to save your bot :(";
            }
            const resp = data.response;
            if (counter < 10) {
                setCounter(counter + 1);
            }
            return resp;
        } else {
            console.log(response);
            return "Sorry, I am not able to process your request at the moment.";
        }
    }

    return (
        <div className="Tuner">
            <Counter count={ counter } />
            <Chat fetchResponse={ fetchResponse } authToken={ authToken } chatId={ chatId }
            greetings={ GREET } cachedMessages={ cached.msg } isBuild={ false } isTunning={ true } />
        </div>
    );
}

export default Tuner;