import React, { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from './components/Firebase';  // Make sure this is your Firebase setup file

// Create AuthContext
const AuthContext = createContext();

// Create useAuth hook to access AuthContext
export function useAuth() {
    return useContext(AuthContext);
}

// AuthProvider component to wrap the app and provide user info
export function AuthProvider({ children }) {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    // Check for authentication state change
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUser(user);
            setLoading(false);  // Ensure loading is set to false once state is resolved
        });

        // Clean up subscription on unmount
        return unsubscribe;
    }, []);

    // While loading, you can display a spinner or a loading state
    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <AuthContext.Provider value ={{ user, setUser }} >
            {children}
        </AuthContext.Provider>
    );
}