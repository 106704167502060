import './UserAgreement.css';
import React from 'react';
import { FaUserCheck, FaGavel, FaLock, FaFileContract, FaHandshake, FaShieldAlt } from 'react-icons/fa';

const UserAgreement = () => {
    return (
        <div className="agreement-container">
            <h1 className="agreement-title">User Agreement</h1>

            {/* Introduction Section */}
            <section className="agreement-section">
                <h2 className="agreement-subtitle">Introduction</h2>
                <p className="agreement-description">
                    This User Agreement outlines the terms and conditions governing the use of our services. By accessing or using our platform, you agree to comply with these terms and conditions. Please read this agreement carefully to understand your rights and responsibilities.
                </p>
            </section>

            {/* User Responsibilities Section */}
            <section className="agreement-section">
                <h2 className="agreement-subtitle">User Responsibilities</h2>
                <div className="responsibility-grid">
                    {/* Fair Use */}
                    <div className="responsibility-card">
                        <FaUserCheck className="responsibility-icon" aria-label="Fair Use Icon" />
                        <h3>Fair Use</h3>
                        <p>You agree to use our services in a fair and legal manner, ensuring that your actions do not violate any laws or infringe on the rights of others.</p>
                    </div>

                    {/* Compliance with Laws */}
                    <div className="responsibility-card">
                        <FaGavel className="responsibility-icon" aria-label="Compliance Icon" />
                        <h3>Compliance with Laws</h3>
                        <p>As a user, you are responsible for complying with all local, national, and international laws and regulations that apply to your use of our services.</p>
                    </div>

                    {/* Account Security */}
                    <div className="responsibility-card">
                        <FaLock className="responsibility-icon" aria-label="Security Icon" />
                        <h3>Account Security</h3>
                        <p>You are responsible for maintaining the confidentiality of your account credentials and ensuring that no unauthorized individuals access your account.</p>
                    </div>

                    {/* Prohibited Activities */}
                    <div className="responsibility-card">
                        <FaFileContract className="responsibility-icon" aria-label="Prohibited Activities Icon" />
                        <h3>Prohibited Activities</h3>
                        <p>Users must refrain from engaging in any activity that compromises the security, integrity, or functionality of our services.</p>
                    </div>
                </div>
            </section>

            {/* Termination and Suspension Section */}
            <section className="agreement-section">
                <h2 className="agreement-subtitle">Termination & Suspension</h2>
                <div className="termination-grid">
                    {/* Termination Rights */}
                    <div className="termination-card">
                        <FaGavel className="termination-icon" aria-label="Termination Icon" />
                        <h3>Termination Rights</h3>
                        <p>We reserve the right to terminate or suspend your account if we find that you have violated this agreement or engaged in prohibited activities.</p>
                    </div>

                    {/* Suspension of Services */}
                    <div className="termination-card">
                        <FaShieldAlt className="termination-icon" aria-label="Suspension Icon" />
                        <h3>Service Suspension</h3>
                        <p>We may suspend access to services in the event of a security breach or to comply with legal requirements.</p>
                    </div>

                    {/* Appeals Process */}
                    <div className="termination-card">
                        <FaHandshake className="termination-icon" aria-label="Appeals Process Icon" />
                        <h3>Appeals Process</h3>
                        <p>If your account is terminated or suspended, you may submit an appeal for review. We will respond to your appeal within a reasonable timeframe.</p>
                    </div>
                </div>
            </section>

            {/* Governing Law Section */}
            <section className="agreement-section">
                <h2 className="agreement-subtitle">Governing Law</h2>
                <p className="agreement-description">
                    This agreement is governed by the laws of the state of Delaware, where our company is incorporated. Any legal disputes or claims arising from this agreement will be resolved in accordance with Delaware state law.
                </p>
            </section>

            {/* Contact Section */}
            <section className="agreement-section">
                <h2 className="agreement-subtitle">Contact Us</h2>
                <p className="agreement-description">
                    If you have any questions or concerns regarding this User Agreement, feel free to contact us at hello@botenders.com. We are here to assist you.
                </p>
            </section>
        </div>
    );
};

export default UserAgreement;
