import './Footer.css';
import React from 'react';

const Footer = ({ onTabClick }) => {
    return (
        <footer className="footer">
            <div className="footer-bg"></div>
            <div className="footer-content">
                <div className="footer-cp">
                    <div>Copyright © 2024 Botenders, Inc. All rights reserved.</div>
                </div>
                <div className="footer-menu">
                    <div onClick={() => onTabClick('privacy')}>Privacy Policy</div>
                    <div onClick={() => onTabClick('user-agreement')}>User Agreement</div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;