import React, { useState, useEffect } from 'react';
import './BotInstances.css';

function BotInstances() {
    const [isEditing, setIsEditing] = useState(false);
    const [isChatting, setIsChatting] = useState(false);
    const [chatbot, setChatbot] = useState({
      name: 'lalala',
      description: 'This is a sample chatbot.',
      type: 'Service',
      tone: 'Friendly',
      businessUrl: 'https://www.example.com',
      productPdfs: ['product1.pdf', 'product2.pdf'],
    });
    const [editedChatbot, setEditedChatbot] = useState(chatbot);

    const handleEditToggle = () => {
      setIsEditing(!isEditing);
      setEditedChatbot(chatbot); // Reset edits if cancelled
    };
  
    const toggleChat = () => {
      setIsChatting(!isChatting);
    };

    useEffect(() => {
      let chatbotContainer;
      if (isChatting) {
        chatbotContainer = document.createElement('div');
        chatbotContainer.id = 'chatbot-container';
        document.body.appendChild(chatbotContainer);

        // Create and append the chatbot script
        const script = document.createElement('script');
        script.src = "https://www.botenders.com/scripts/test.js";
        script.setAttribute('data-api-key', 'your-api-key');
        script.async = true;
        script.id = 'chatbot-script';
        chatbotContainer.appendChild(script);
      } else {
        // Remove the chatbot container, which also removes the script within it
        chatbotContainer = document.getElementById('chatbot-container');
        if (chatbotContainer) {
          chatbotContainer.parentNode.removeChild(chatbotContainer);
        }
      }
    }, [isChatting]);
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setEditedChatbot({ ...editedChatbot, [name]: value });
    };
  
    const updateChatbot = (updatedChatbot) => {
      setChatbot(updatedChatbot);
    };
  
    const handleSave = () => {
      updateChatbot(editedChatbot);
      setIsEditing(false);
    };
  
    return (
      <div className="bot-instance-container">
        <div className="chatbot-parameters">
          {isEditing ? (
            <div className="edit-form">
              <label>
                Bot Name:
                <input
                  className='inputtt'
                  type="text"
                  name="name"
                  value={editedChatbot.name}
                  onChange={handleChange}
                />
              </label>
              <label>
                Bot Description:
                <input
                  className='inputtt'
                  type="text"
                  name="description"
                  value={editedChatbot.description}
                  onChange={handleChange}
                />
              </label>
              <label>
                Chatbot Type:
                <select
                  name="type"
                  value={editedChatbot.type}
                  onChange={handleChange}
                >
                  <option value="Service">Service</option>
                  <option value="Technical Support">Technical Support</option>
                  <option value="Sales">Sales</option>
                  <option value="Other">Other</option>
                </select>
              </label>
              <label>
                Chatbot Tone:
                <input
                  className='inputtt'
                  type="text"
                  name="tone"
                  value={editedChatbot.tone}
                  onChange={handleChange}
                />
              </label>
              <label>
                Business URL:
                <input
                  className='inputtt'
                  type="text"
                  name="businessUrl"
                  value={editedChatbot.businessUrl}
                  onChange={handleChange}
                />
              </label>
              <label>
                Product PDFs (comma-separated):
                <input
                  className='inputtt'
                  type="text"
                  name="productPdfs"
                  value={editedChatbot.productPdfs.join(', ')}
                  onChange={(e) =>
                    setEditedChatbot({
                      ...editedChatbot,
                      productPdfs: e.target.value.split(',').map((pdf) => pdf.trim()),
                    })
                  }
                />
              </label>
              <div className="booton-group">
                <button onClick={handleSave} className="booton">Save</button>
                <button onClick={handleEditToggle} className="booton">Cancel</button>
              </div>
            </div>
          ) : (
            <div className="parameters-display">
              <button onClick={handleEditToggle} className="booton">Edit Parameters</button>
              <p>
                <strong>Bot Name:</strong> {chatbot.name}
              </p>
              <p>
                <strong>Bot Description:</strong> {chatbot.description}
              </p>
              <p>
                <strong>Chatbot Type:</strong> {chatbot.type}
              </p>
              <p>
                <strong>Chatbot Tone:</strong> {chatbot.tone}
              </p>
              <p>
                <strong>Business URL:</strong>{' '}
                <a href={chatbot.businessUrl}>{chatbot.businessUrl}</a>
              </p>
              <p>
                <strong>Product PDFs:</strong> {chatbot.productPdfs.join(', ')}
              </p>
              <button onClick={toggleChat} className="booton">
                {isChatting ? 'Close the Chatbot' : 'Chat with the Bot'}
              </button>
            </div>
          )}
        </div>
      </div>
    );
}

export default BotInstances;