// src/components/Products/index.js

import React from 'react';
import './Products.css';
import {
  FaGlobe,
  FaFacebookMessenger,
  FaCog,
  FaStethoscope,
  FaTools,
  FaShoppingCart,
  FaDatabase,
  FaMapMarkedAlt,
  FaPuzzlePiece // New Icon for Custom Application Integration
} from 'react-icons/fa';

const Products = () => {
  return (
    <div className="products-container">
      <h1 className="products-title">Our Products</h1>
      
      {/* Applications Section */}
      <section className="products-section">
        <h2 className="products-subtitle">Applications</h2>
        <div className="applications-grid">
          
          {/* Medical Field */}
          {/* <div className="application-card">
            <FaStethoscope className="application-icon" />
            <h3>Medical Field</h3>
            <ul>
              <li>Access to Specific Patient Medical Records</li>
              <li>Handling Patient Inquiries Efficiently</li>
              <li>Content Delivery Based on Patient Status and Needs</li>
            </ul>
          </div> */}
          
          {/* Hardware or Other Products */}
          <div className="application-card">
            <FaTools className="application-icon" />
            <h3>Hardware & Other Products</h3>
            <ul>
              <li>Troubleshooting General Product Problems</li>
              <li>Providing Installation Guidance</li>
              <li>Managing Warranty and Service Requests</li>
            </ul>
          </div>
          
          {/* General Merchandise */}
          <div className="application-card">
            <FaShoppingCart className="application-icon" />
            <h3>General Merchandise</h3>
            <ul>
              <li>Product Inquiry and Information</li>
              <li>Sales and Order Processing Assistance</li>
              <li>Managing Customer Feedback and Reviews</li>
            </ul>
          </div>
          
          {/* Internal Knowledge */}
          <div className="application-card">
            <FaDatabase className="application-icon" />
            <h3>Internal Knowledge Management</h3>
            <ul>
              <li>Managing Internal Company Knowledge Bases</li>
              <li>Providing Instant Answers to Internal Queries</li>
              <li>Facilitating Employee Onboarding and Training</li>
            </ul>
          </div>
          
          {/* Web Navigation Bot */}
          <div className="application-card">
            <FaMapMarkedAlt className="application-icon" />
            <h3>Web Navigation Bot</h3>
            <ul>
              <li>Directing Consumers to Specific Pages Based on Their Requests</li>
              <li>Enhancing User Experience with Smart Navigation Assistance</li>
              <li>Reducing Bounce Rates by Guiding Users Efficiently</li>
            </ul>
          </div>
          
        </div>
      </section>
      
      {/* Chatbot Integration Section */}
      <section className="products-section">
        <h2 className="products-subtitle">Chatbot Integration</h2>
        <p className="products-description">
          Seamlessly integrate our advanced chatbots across various platforms to enhance your customer interactions and streamline operations.
        </p>
        <div className="integration-grid">
          <div className="integration-card">
            <FaGlobe className="integration-icon" />
            <h3>Website Integration</h3>
            <p>Embed our intelligent chatbots directly into your website to provide instant support and engage visitors effectively.</p>
          </div>
          <div className="integration-card">
            <FaFacebookMessenger className="integration-icon" />
            <h3>Social Media Platforms</h3>
            <p>Connect with customers on popular social media platforms like Messenger, WeChat, and more, ensuring a consistent experience across channels.</p>
          </div>
          <div className="integration-card">
            <FaPuzzlePiece className="integration-icon" />
            <h3>Custom Application Integration</h3>
            <p>Integrate our chatbots with your unique business applications to tailor interactions and automate specialized workflows seamlessly.</p>
          </div>
        </div>
      </section>
      
      {/* Chatbot Types Section */}
      <section className="products-section">
        <h2 className="products-subtitle">Chatbot Types</h2>
        <p className="products-description">
          Our versatile chatbots cater to diverse business needs, ensuring optimal performance and user satisfaction across various functions.
        </p>
        <div className="types-grid">
          <div className="type-card">
            <FaCog className="type-icon" />
            <h3>Service Chatbot</h3>
            <p>Enhance your customer service with chatbots that handle inquiries, provide information, and resolve common issues efficiently.</p>
          </div>
          <div className="type-card">
            <FaStethoscope className="type-icon" />
            <h3>Technical Support Chatbot</h3>
            <p>Provide technical assistance with chatbots designed to troubleshoot problems, guide users through solutions, and escalate issues when necessary.</p>
          </div>
          <div className="type-card">
            <FaShoppingCart className="type-icon" />
            <h3>Sales Chatbot</h3>
            <p>Boost your sales with chatbots that engage customers, recommend products, and assist in completing transactions seamlessly.</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Products;
