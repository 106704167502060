// src/components/Privacy/index.js

import React from 'react';
import './Privacy.css';
import { FaShieldAlt, FaLock, FaUserSecret, FaHandsHelping, FaEnvelope, FaBullhorn, FaDatabase } from 'react-icons/fa';

const Privacy = () => {
    return (
        <div className="privacy-container">
            <h1 className="privacy-title">Privacy Policy</h1>

            {/* Introduction Section */}
            <section className="privacy-section">
                <h2 className="privacy-subtitle">Introduction</h2>
                <p className="privacy-description">
                    We prioritize your privacy and are committed to protecting your personal information. This Privacy Notice outlines how we collect, use, store, and safeguard your data when you interact with our services. Continuing to visit our site means you accept our privacy policy.
                </p>
            </section>

            {/* Information We Collect Section */}
            <section className="privacy-section">
                <h2 className="privacy-subtitle">Information We Collect</h2>
                <div className="info-grid">
                    {/* Session Storage */}
                    <div className="info-card">
                        <FaLock className="info-icon" aria-label="Session Storage Icon" />
                        <h3>Session Storage</h3>
                        <p>We utilize sessionStorage to cache your interactions temporarily, enhancing your experience by providing quicker access to frequently used features.</p>
                    </div>

                    {/* User Inputs */}
                    <div className="info-card">
                        <FaUserSecret className="info-icon" aria-label="User Inputs Icon" />
                        <h3>User Inputs</h3>
                        <p>Your inputs are securely cached on our servers to personalize your experience and improve our services.</p>
                    </div>

                    {/* Document Uploads */}
                    <div className="info-card">
                        <FaShieldAlt className="info-icon" aria-label="Document Storage Icon" />
                        <h3>Document Uploads</h3>
                        <p>Uploaded documents are stored in our encrypted database, ensuring that only you and your designated models have access to them.</p>
                    </div>

                    {/* Additional Information */}
                    <div className="info-card">
                        <FaLock className="info-icon" aria-label="Additional Information Icon" />
                        <h3>Additional Information</h3>
                        <p>Any other information we collect is handled with the same level of security and confidentiality, stored in encrypted formats accessible solely to authorized personnel.</p>
                    </div>
                </div>
            </section>

            {/* How We Use Your Information Section */}
            <section className="privacy-section">
                <h2 className="privacy-subtitle">How We Use Your Information</h2>
                <div className="usage-grid">
                    {/* Usage Card */}
                    <div className="usage-card">
                        <FaHandsHelping className="usage-icon" aria-label="Usage Icon" />
                        <h3>Enhancing Services</h3>
                        <p>We use your information to improve and personalize our services, ensuring a better user experience tailored to your needs.</p>
                    </div>

                    {/* Analytics Card */}
                    <div className="usage-card">
                        <FaLock className="usage-icon" aria-label="Analytics Icon" />
                        <h3>Data Analysis</h3>
                        <p>Analyzing usage data helps us understand how our services are utilized, allowing us to make informed decisions for future developments.</p>
                    </div>

                    {/* Communication Card */}
                    <div className="usage-card">
                        <FaEnvelope className="usage-icon" aria-label="Communication Icon" />
                        <h3>Customer Support</h3>
                        <p>Your information enables us to provide timely and effective support, addressing any queries or issues you may encounter.</p>
                    </div>

                    {/* Marketing and Communication Card */}
                    <div className="usage-card">
                        <FaBullhorn className="usage-icon" aria-label="Marketing and Communication Icon" />
                        <h3>Marketing & Communication</h3>
                        <p>We may use your information to send you updates, newsletters, and promotional materials that may interest you.</p>
                    </div>
                </div>
            </section>

            {/* Security Measures Section */}
            <section className="privacy-section">
                <h2 className="privacy-subtitle">Security Measures</h2>
                <div className="security-grid">
                    {/* Encryption Card */}
                    <div className="security-card">
                        <FaShieldAlt className="security-icon" aria-label="Encryption Icon" />
                        <h3>Data Encryption</h3>
                        <p>All sensitive data, including documents and personal information, is encrypted both in transit and at rest to prevent unauthorized access.</p>
                    </div>

                    {/* Access Control Card */}
                    <div className="security-card">
                        <FaLock className="security-icon" aria-label="Access Control Icon" />
                        <h3>Access Control</h3>
                        <p>Access to your data is strictly limited to authorized personnel only, ensuring that your information remains confidential.</p>
                    </div>

                    {/* Monitoring Card */}
                    <div className="security-card">
                        <FaUserSecret className="security-icon" aria-label="Monitoring Icon" />
                        <h3>Continuous Monitoring</h3>
                        <p>We continuously monitor our systems for any suspicious activities, enabling us to respond swiftly to potential security threats.</p>
                    </div>

                    {/* Data Backup & Recovery Card */}
                    <div className="security-card">
                        <FaDatabase className="security-icon" aria-label="Data Backup & Recovery Icon" />
                        <h3>Data Backup & Recovery</h3>
                        <p>Regular data backups ensure that your information is safe and can be restored promptly in the event of any unforeseen incidents.</p>
                    </div>
                </div>
            </section>

            {/* Your Rights Section */}
            <section className="privacy-section">
                <h2 className="privacy-subtitle">Your Rights</h2>
                <div className="rights-grid">
                    {/* Access Rights Card */}
                    <div className="rights-card">
                        <FaUserSecret className="rights-icon" aria-label="Access Rights Icon" />
                        <h3>Access Your Data</h3>
                        <p>You have the right to request access to the personal data we hold about you, allowing you to verify its accuracy and completeness.</p>
                    </div>

                    {/* Correction Rights Card */}
                    <div className="rights-card">
                        <FaLock className="rights-icon" aria-label="Correction Rights Icon" />
                        <h3>Rectify Inaccuracies</h3>
                        <p>If you find any inaccuracies in your data, you can request corrections to ensure that your information is up-to-date and accurate.</p>
                    </div>

                    {/* Deletion Rights Card */}
                    <div className="rights-card">
                        <FaShieldAlt className="rights-icon" aria-label="Deletion Rights Icon" />
                        <h3>Delete Your Data</h3>
                        <p>You have the right to request the deletion of your personal data, subject to certain legal obligations and exemptions.</p>
                    </div>

                    {/* Restriction Rights Card */}
                    <div className="rights-card">
                        <FaLock className="rights-icon" aria-label="Restriction Rights Icon" />
                        <h3>Restrict Processing</h3>
                        <p>You can request the restriction of processing your data under specific circumstances, such as contesting its accuracy or objecting to its use.</p>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Privacy;