import "./App.css";
import React, { useState, useEffect, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Greeter from "./components/Greeter";
import Builder from "./components/Builder";
import About from "./components/About";
import Pricing from "./components/Pricing";
import Products from "./components/Products";
import Contact from "./components/Contact";
import Privacy from "./components/Privacy";
import Login from "./components/Login";
import Account from "./components/Account";
import Signup from "./components/Signup";
import Verify from "./components/Signup/Verify";
import UserAgreement from "./components/UserAgreement";
import BackgroundAnimation from "./components/Backgroundanimation";
import { useAuth } from "./Auth";

function App() {
  const { user } = useAuth();
  const [userId, setUserId] = useState(null);
  const [activeTab, setActiveTab] = useState("home");
  const [publicToken, setPublicToken] = useState(null);
  const [privateToken, setPrivateToken] = useState(null);
  const [greets, setGreets] = useState({ id: null, msg: [] });
  const [builds, setBuilds] = useState({ id: null, msg: [] });

  const renderTab = () => {
    switch (activeTab) {
      case "home":
        return null;
      case "build":
        return null;
      case "about":
        return <About />;
      case "pricing":
        return <Pricing user={ user } navigateTo={ navigateTo }/>;
      case "products":
        return <Products />;
      case "contact":
        return <Contact />;
      case "privacy":
        return <Privacy />;
      case "user-agreement":
        return <UserAgreement />;
      case "account":
        return user ? <Account authToken={privateToken} /> : <Login navigateTo={navigateTo} />;
      case "signup": // Add this case for signup
        return <Signup navigateTo={navigateTo}/>;
      case "verify": // Add this case for verify
        return <Verify navigateTo={navigateTo}/>;
      default:
        return "Home";
    }
  };

  useEffect(() => {
    const storedGreets = sessionStorage.getItem("greets");
    const storedBuilds = sessionStorage.getItem("builds");

    if (storedGreets) {
      setGreets(JSON.parse(storedGreets));
    }

    if (storedBuilds) {
      setBuilds(JSON.parse(storedBuilds));
    }
  }, []);

  useEffect(() => {
    // const storedUserId = sessionStorage.getItem("userId");
    const storedPublicToken = sessionStorage.getItem("publicToken");
    const storedPrivateToken = sessionStorage.getItem("privateToken");

    if (user) {
      setUserId(user.uid);
    } else {
      const newUserId = uuidv4();
      setUserId(newUserId);
      setActiveTab("privacy");
    }

    if (storedPublicToken) {
      setPublicToken(storedPublicToken);
    }

    if (storedPrivateToken) {
      setPrivateToken(storedPrivateToken);
    }
  }, [user]);

  const requestTokens = useCallback(
    async (userId) => {
      try {
        const response = await fetch(`/api/v1/auth/public/${userId}`);
        if (!response.ok) {
          throw new Error(`Error fetching tokens: ${response.statusText}`);
        }
        const data = await response.json();
        const publicToken = data.token;

        setPublicToken(publicToken);
        if (!privateToken) {
          setPrivateToken(publicToken);
        }

        sessionStorage.setItem("publicToken", publicToken);
        // sessionStorage.setItem('privateToken', privateToken);
      } catch (error) {
        console.error("Error fetching tokens:", error);
      }
    },
    [privateToken]
  );

  useEffect(() => {
    if (userId && (!publicToken || !privateToken)) {
      requestTokens(userId);
    }
  }, [userId, publicToken, privateToken, requestTokens]);

  function onTabClick(tab) {
    if (tab === activeTab && tab !== "build") {
      setActiveTab("home");
      return;
    }
    setActiveTab(tab);
  }

  function navigateTo(tab) {
    setActiveTab(tab);
  }
  const isFullWidth = activeTab === "home" || activeTab === "build";

  return (
    // <AuthProvider>
    <div className="App">
      <BackgroundAnimation />
      <Header onTabClick={onTabClick} activeTab={activeTab} user={user} />
      <div className="main-container">
        <div className="flex-container">
          <div
            className={`chat-container ${
              isFullWidth ? "full-width" : "half-width"
            }`}
          >
            {activeTab === "build" ? (
              <Builder
                cached={builds}
                authToken={privateToken}
                setPrivateToken={setPrivateToken}
                user={user}
              />
            ) : (
              <Greeter
                cached={greets}
                authToken={publicToken}
                navigateTo={navigateTo}
              />
            )}
          </div>
          {!isFullWidth && (
            <div className="right-side-container">{renderTab()}</div>
          )}
        </div>
      </div>
      <Footer onTabClick={onTabClick} />
    </div>
    // </AuthProvider>
  );
}

export default App;
