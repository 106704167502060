import './Preview.css';
import React from 'react';

const getFileIcon = (fileExt) => {
    switch (fileExt) {
        case 'pdf':
            return '/icons/pdf-icon.svg';  // You can use custom icons
        case 'doc':
        case 'docx':
            return '/icons/docx-icon.svg';
        case 'jpg':
        case 'jpeg':
        case 'png':
            return '/icons/image-icon.svg';
        case 'zip':
        case 'rar':
            return '/icons/zip-icon.svg';
        default:
            return '/icons/file-icon.svg';  // Default icon for other file types
    }
};

const Preview = ({ fileName, fileExt }) => {
    const iconSrc = getFileIcon(fileExt);

    return (
        <div className="file-preview">
            <img src={iconSrc} alt={fileExt} className="file-icon" />
            <p className="file-name">{fileName}</p>
        </div>
    );
};

export default Preview;