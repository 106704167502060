// src/components/BillingInfo.js

import React, { useEffect, useState } from 'react';
import './Billing.css';

const Billing = ({ authToken }) => {
    const [billingData, setBillingData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, ] = useState('');

    useEffect(() => {
        const fetchBillingInfo = async () => {
            // Commented out the backend API call for testing purposes
            /*
            try {
                const response = await fetch('/api/v1/billing', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authToken}`
                    }
                });

                if (response.ok) {
                    const data = await response.json();
                    setBillingData(data);
                } else {
                    const errorData = await response.json();
                    setError(errorData.message || 'Failed to fetch billing information.');
                }
            } catch (err) {
                setError('An error occurred while fetching billing information.');
            } finally {
                setLoading(false);
            }
            */

            // Fake data for testing
            const fakeData = {
                name: 'John Doe',
                email: 'john.doe@example.com',
                address: '1234 Elm Street, Springfield, IL',
                cardType: 'Visa',
                cardLast4: '1234',
                expirationDate: '12/25',
                planName: 'Premium',
                nextBillingDate: '2024-11-05'
            };

            setBillingData(fakeData);
            setLoading(false);
        };

        fetchBillingInfo();
    }, [authToken]);

    const handleCancelSubscription = () => {
        const confirmCancel = window.confirm('Are you sure you want to cancel your subscription? This action cannot be undone.');
        if (confirmCancel) {
            // Here you would typically make an API call to cancel the subscription
            // For demonstration, we'll just log to the console and show an alert
            console.log('Subscription cancelled.');
            alert('Your subscription has been cancelled successfully.');
            // Optionally, you can redirect the user or update the UI accordingly
        }
    };

    if (loading) {
        return <div className="billing-info-container"><p className="loading-message">Loading...</p></div>;
    }

    if (error) {
        return <div className="billing-info-container error-message">{error}</div>;
    }

    return (
        <div className="billing-info-container">
            <div className="billing-content">
                <div className="billing-section">
                    <h3 className="section-title">Personal Details</h3>
                    <div className='billing-info-text'>
                        <p><strong>Name:</strong> {billingData.name}</p>
                        <p><strong>Email:</strong> {billingData.email}</p>
                        <p><strong>Address:</strong> {billingData.address}</p>
                    </div>
                </div>
                <div className="billing-section">
                    <h3 className="section-title">Payment Method</h3>
                        <div className='billing-info-text'>
                        <p><strong>Card Type:</strong> {billingData.cardType}</p>
                        <p><strong>Card Number:</strong> **** **** **** {billingData.cardLast4}</p>
                        <p><strong>Expiration Date:</strong> {billingData.expirationDate}</p>
                    </div>
                </div>
                <div className="billing-section">
                    <h3 className="section-title">Subscription Plan</h3>
                    <div className='billing-info-text'>
                    <p><strong>Plan:</strong> {billingData.planName}</p>
                    <p><strong>Next Billing Date:</strong> {billingData.nextBillingDate}</p>
                    </div>
                    <button className="cancel-button" onClick={handleCancelSubscription}>
                        Cancel Subscription
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Billing;
