import "./Greeter.css";
import React, { useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import Chat from '../Chat';

const GREET = `
# Welcome to Botenders!

We specialize in creating **custom AI chatbots** for your website and social media platforms, allowing you to engage with your customers and streamline support in just minutes. Whether you're looking to **automate customer inquiries**, **product troubleshooting**, or **integrate AI into your business**, we’ve got you covered!

**Ask me anything** to learn how our easy-to-deploy chatbots can help your business **save time**, **increase efficiency**, and **enhance customer satisfaction**.
`;

const TABS = {
    "ABT": "about",   
    "PRI": "pricing",
    "CON": "contact",
    "SUP": "contact",
    "PDT": "products",
    "PVC": "privacy",
    "UAG": "user-agreement",
};

const Greeter = ({ cached, authToken, navigateTo }) => {
    const [chatId] = useState(cached.id ? cached.id : uuidv4());

    async function fetchResponse(authToken, userInput, chatId, controller, _) {
        const response = await fetch("/api/v1/chat/" + chatId, {
            method: "POST",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: `Bearer ${authToken}`,
            },
            body: new URLSearchParams({
                inputs: userInput,
            }),
            signal: controller.signal,
        });
        if (response.ok) {
            const data = await response.json();
            if (TABS[data.action]) {
                navigateTo(TABS[data.action]);
            }
            return data.response;
        }
        return "Whoops! Something went sideways. Let's pretend that didn't happen.";
    }

    return (
        <div className="Greeter">
            <Chat fetchResponse={ fetchResponse } authToken={ authToken } chatId={ chatId }
            greetings={ GREET } cachedMessages={ cached.msg } isBuild={ false } />
        </div>
    );
};

export default Greeter;