import './Header.css';
import React, { useState } from 'react';

const Header = ({ onTabClick, activeTab, user }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header className="header">
            <div className="header-bg"></div>
            <div className="logo">
                <img src="./logo.svg" alt="Logo" className="logo-image" />
                <div className="logo-box">
                    <div className="logo-text">Botenders</div>
                    <div className="logo-subtext">Effortless AI integration at a price you’ll love</div>
                </div>
            </div>
            <div className={`hamburger ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                &#9776;
            </div>
            <div className={`menu ${isMenuOpen ? 'menu-open' : ''}`}>
                <div onClick={() => { onTabClick('home'); toggleMenu(); }}
                    className={`home-tab ${activeTab === 'home' ? 'active' : ''}`}>
                    Home
                </div>
                <div onClick={() => { onTabClick('build'); toggleMenu(); }}
                    id='build-tab'
                    className={`build-tab ${activeTab === 'build' ? 'active' : ''}`}>
                    Build
                </div>
                <div onClick={() => { onTabClick('about'); toggleMenu(); }}
                    className={`about-tab ${activeTab === 'about' ? 'active' : ''}`}>
                    About
                </div>
                <div onClick={() => { onTabClick('pricing'); toggleMenu(); }}
                    className={`pricing-tab ${activeTab === 'pricing' ? 'active' : ''}`}>
                    Pricing
                </div>
                <div onClick={() => { onTabClick('products'); toggleMenu(); }}
                    className={`services-tab ${activeTab === 'products' ? 'active' : ''}`}>
                    Products
                </div>
                <div onClick={() => { onTabClick('contact'); toggleMenu(); }}
                    className={`contact-tab ${activeTab === 'contact' ? 'active' : ''}`}>
                    Contact
                </div>
                <div onClick={() => { onTabClick('account'); toggleMenu(); }}
                    className={`account-tab ${activeTab === 'account' || activeTab === 'signup' ? 'active' : ''}`}>
                    {user ? 'Account' : 'Login'}
                </div>
            </div>
        </header>
    );
};

export default Header;
