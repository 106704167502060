import React from 'react';
import './Loading.css';
import lottieAnimation from './loading.json';
import { Player } from '@lottiefiles/react-lottie-player';

const Loading = ({user}) => {
    return (
        <div className="loading-container">
            {/* {
                user ? <h2 className='loading-p'>Botending...</h2> : <h2 className='loading-p'>Note that you're not logged in, any progress will be lost after you exit the website...</h2>
            } */}
            {/* <div className="big-loading-spinner" /> */}
            <Player
                autoplay
                loop
                src={lottieAnimation}
                style={{ height: '100px', width: '100px' }}
            />
        </div>
    );
};

export default Loading;