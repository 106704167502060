import React from 'react';
import './About.css';
import {
    FaBullseye,
    FaHandshake,
    FaCog,
    FaChartLine,
    FaRocket
} from 'react-icons/fa';

const About = () => {
    return (
        <section className="about-container">
            <h2 className="about-title">ABOUT US</h2>
            <p className="about-description">
                At Botenders, we make AI chatbot integration accessible, affordable, and simple for all businesses. Our goal is to empower organizations to streamline customer support and automate processes without the high costs or complex setup.
                <br />
                <strong style={{ color: '#FF5733' }}>Build your chatbot in under 5 minutes!</strong>
            </p>

            <div className="about-cards">
                <article className="about-card">
                    <h3 className="about-card-title">
                        <FaBullseye className="about-icon" aria-label="Mission Icon" /> Mission
                    </h3>
                    <p className="about-card-description">
                        Our mission is to democratize AI with easy, scalable solutions, enabling businesses of all sizes to harness the power of automation.
                    </p>
                </article>

                <article className="about-card">
                    <h3 className="about-card-title">
                        <FaHandshake className="about-icon" aria-label="Why Us Icon" /> Why Us
                    </h3>
                    <p className="about-card-description">
                        We offer advanced AI technology with simple flat-rate pricing. No hidden fees—just transparent, affordable plans tailored to your needs.
                    </p>
                </article>

                <article className="about-card">
                    <h3 className="about-card-title">
                        <FaCog className="about-icon" aria-label="Technology Icon" /> Technology
                    </h3>
                    <p className="about-card-description">
                        Our platform uses state-of-the-art AI to deliver chatbots that understand your products and provide human-like interactions. Reliable, scalable, and built for growth.
                    </p>
                </article>

                <article className="about-card">
                    <h3 className="about-card-title">
                        <FaChartLine className="about-icon" aria-label="Empowerment Icon" /> Empowerment
                    </h3>
                    <p className="about-card-description">
                        We make AI accessible to all businesses, helping you boost efficiency, enhance customer engagement, and stay competitive.
                    </p>
                </article>

                <article className="about-card">
                    <h3 className="about-card-title">
                        <FaRocket className="about-icon" aria-label="Coming Soon Icon" /> Coming Soon
                    </h3>
                    <p className="about-card-description">
                        We're in pre-release—join our waitlist and help shape the future of business automation and customer interaction.
                    </p>
                </article>
            </div>
        </section>
    );
};

export default About;