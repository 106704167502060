import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import CryptoJS from 'crypto-js';

const encryptedFirebaseConfig = {
    apiKey: "SbJ1kSqi+GaKFf+L5uVac3Fij+ruhDDiYVhOZgx78v2wYDMJwHevC16t0u73kf+o",
    authDomain: "iLbYLs2OFF2olntkyZCmoZTAqCKuXI0IhlqNYH+rYLM=",
    projectId: "zrt4a6pXTrX2qklmOI4XAQ==",
    storageBucket: "YIXYO7ag9Qmc/vquk0VSBC10Bu71axw6MecQuclZ5mM=",
    messagingSenderId: "Qh/zDeztJ2gd9dyMDmxjZQ==",
    appId: "DnKon0AIOVqOWq9SZY+3uGpIN7JrKNdiNqkQi/b7oDJZVUhClZ01qkFcnmGsEbQ7",
    measurementId: "Wrl2RnZ9S0egfTtxIPEhgg=="
};

const decrypt = (encryptedBase64, key, iv) => {
    const keyHex = CryptoJS.enc.Hex.parse(key);
    const ivHex = CryptoJS.enc.Hex.parse(iv);
    
    const encrypted = CryptoJS.enc.Base64.parse(encryptedBase64);  // Base64 decode
    const decrypted = CryptoJS.AES.decrypt({ciphertext: encrypted}, keyHex, {
        iv: ivHex,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8);  // Convert to UTF-8 string
};

const getFirebaseConfig = () => {
    const secretKey = process.env.REACT_APP_FIREBASE_SECRET_KEY;
    const ivKey = process.env.REACT_APP_FIREBASE_IV_KEY;

    if (!secretKey || !ivKey) {
        throw new Error('Missing secret or IV key in environment variables');
    }

    const firebaseConfig = {
        apiKey: decrypt(encryptedFirebaseConfig.apiKey, secretKey, ivKey),
        authDomain: decrypt(encryptedFirebaseConfig.authDomain, secretKey, ivKey),
        projectId: decrypt(encryptedFirebaseConfig.projectId, secretKey, ivKey),
        storageBucket: decrypt(encryptedFirebaseConfig.storageBucket, secretKey, ivKey),
        messagingSenderId: decrypt(encryptedFirebaseConfig.messagingSenderId, secretKey, ivKey),
        appId: decrypt(encryptedFirebaseConfig.appId, secretKey, ivKey),
        measurementId: decrypt(encryptedFirebaseConfig.measurementId, secretKey, ivKey)
    };

    return firebaseConfig;
};

const firebaseConfig = getFirebaseConfig();

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
