// src/components/Contact/index.js

import React from "react";
import "./Contact.css";
// import {
//     FaEnvelope,
//     // FaPhoneAlt,
//     // FaMapMarkerAlt,
//     FaFacebookF,
//     FaTwitter,
//     FaLinkedinIn,
//     FaInstagram,
// } from "react-icons/fa";
import emailjs from "emailjs-com";

const Contact = () => {
    const handleSubmit = (e) => {
        e.preventDefault();
        emailjs
            .sendForm("service_f4lf26h", "template_b589gg6", e.target, 'cffM9ylj1_Y6nV9uj')
            .then(
                (result) => {
                    console.log(result.text);
                    alert("Message sent successfully");
                },
                (error) => {
                    console.log(error.text);
                    alert("Failed to send message");
                }
            );
    };

    return (
        <section className="contact-container">
            <h2 className="contact-heading">Get in Touch</h2>
            <div className="contact-info">
                <p>
                    We'd love to hear from you! Whether you have a question about
                    features, trials, pricing, or anything else, our team is ready to
                    answer all of your questions.
                </p>
            </div>
            <form className="contact-form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Your Name"
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Your Email"
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea
                        id="message"
                        name="message"
                        rows="5"
                        placeholder="Your Message"
                        required
                    ></textarea>
                </div>
                <button type="submit" className="submit-btn">
                    Send Message
                </button>
            </form>
        </section>
    );
};

export default Contact;
