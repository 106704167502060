import "./Tune.css";
import React from "react";

const Tune = ({ setScore, handleTune }) => {
    const ratings = [1, 2, 3, 4, 5];

    return (
        <div className="Tune">
            <div className="Tune_question">How did I do?</div>
            <div className="Tune__rating">
                {ratings.map((rating) => (
                    <button
                        key={rating}
                        className="Tune__circle"
                        onClick={() => handleTune(rating)}
                    >
                        {rating}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default Tune;