// src/components/AccountSettings.js

import React, { useState } from 'react';
import './AccountSettings.css';

const AccountSettings = ({ user, handleLogout, auth }) => {
    const [email, setEmail] = useState(user.email);
    const [password, setPassword] = useState('');
    const [apiKey, setApiKey] = useState('************************');
    const [error, ] = useState('');
    const [success, setSuccess] = useState('');

    const handleEmailChange = (e) => {
        e.preventDefault();
        // Implement email change logic here
        setSuccess('Email updated successfully.');
    };

    const handlePasswordChange = (e) => {
        e.preventDefault();
        // Implement password change logic here
        setSuccess('Password updated successfully.');
    };

    const handleClearData = async () => {
        if (window.confirm("Are you sure you want to clear all of your data? This action cannot be undone.")) {
            
            try {
                const response = await fetch('/api/v1/delete', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${auth}`,
                    },
                });

                if (!response.ok) {
                    setSuccess('All data has been cleared.');
                } else {
                    setSuccess('An error occurred while clearing data.');
                }
            } catch (error) {
                setSuccess('An error occurred while clearing data.');
            }
        }
    };

    const handleDeleteAccount = () => {
        // Ask for confirmation before deleting the account
        if (window.confirm("Are you sure you want to delete your account? This action is irreversible.")) {
            // Implement account deletion logic here
            setSuccess('Your account has been scheduled for deletion.');
        }
    };

    const handleApiKeyReset = () => {
        // Implement API key reset logic here
        setApiKey('new-api-key-generated');
        setSuccess('API Key has been reset.');
    };

    return (
        <div className="account-settings-container">

            {error && <div className="messagea error-message">{error}</div>}
            {success && <div className="messagea success-message">{success}</div>}

            <div className="accountsetting-content">
                <div className="account-section">
                    <h3 className="section-title">Change Email</h3>
                    <form onSubmit={handleEmailChange}>
                        <label htmlFor="email">New Email Address:</label>
                        <input
                            className="inputttttt"
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <button type="submit" className="btn primary-btn">Update Email</button>
                    </form>
                </div>

                <div className="account-section">
                    <h3 className="section-title">Change Password</h3>
                    <form onSubmit={handlePasswordChange}>
                        <label htmlFor="password">New Password:</label>
                        <input
                            className="inputttttt"
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <button type="submit" className="btn primary-btn">Update Password</button>
                    </form>
                </div>

                <div className="account-section">
                    <h3 className="section-title">API Key Management</h3>
                    <p><strong>Current API Key:</strong> {apiKey}</p>
                    <button onClick={handleApiKeyReset} className="btn secondary-btn">Reset API Key</button>
                </div>

                <div className="account-section">
                    <h3 className="section-title">Privacy Settings</h3>
                    <button onClick={handleClearData} className="btn secondary-btn">Clear All Data</button>
                </div>

                <div className="account-section">
                    <h3 className="section-title">Delete Account</h3>
                    <button onClick={handleDeleteAccount} className="btn danger-btn">Delete Account</button>
                </div>
            </div>

            <div className="signout-container">
                <button className="btn signout-button" onClick={handleLogout}>Sign Out</button>
            </div>
        </div>
    );
};

export default AccountSettings;
