import React, { useState, useEffect } from 'react';
import './ChatbotForm.css';

const ChatbotForm = ({ chatbotConfig, handleChange, handleSubmit, pdfFiles, handlePdfChange, handlePdfDelete }) => {
    const [extractionStatus, setExtractionStatus] = useState('');
    const [checkingExtraction, setCheckingExtraction] = useState(false);
    const [debouncedUrl, setDebouncedUrl] = useState(''); // Debounced URL state
    const [crawlAllowed, setCrawlAllowed] = useState(false);

    const checkWebsiteExtraction = async (websiteUrl) => {
        if (!websiteUrl) {
            setExtractionStatus('Please provide a valid website URL.');
            return;
        }
    
        setCheckingExtraction(true);
        setExtractionStatus('');
    
        try {
            // Automatically add 'https://' if it's missing
            let formattedUrl = websiteUrl.trim();
            if (!formattedUrl.startsWith('http://') && !formattedUrl.startsWith('https://')) {
                formattedUrl = `https://${formattedUrl}`;
            }
    
            // Remove any trailing slash at the end of the URL
            formattedUrl = formattedUrl.replace(/\/+$/, '');
            const response = await fetch(`/api/v1/proxy?url=${formattedUrl}`,
                // {
                //     headers: {
                //         'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.3',
                //     },
                // }
            );
            if (!response.ok) {
                throw new Error('Failed to fetch robots.txt');
            }
    
            const text = await response.text();
    
            // Parse robots.txt content
            const lines = text.split('\n');
            let currentUserAgent = '*';
            let allowRules = [];
            let disallowRules = [];
            let isApplicableForUserAgent = false;
    
            lines.forEach((line) => {
                const cleanedLine = line.trim().toLowerCase();
    
                // Ignore empty lines and comments
                if (!cleanedLine || cleanedLine.startsWith('#')) return;
    
                // Check for User-agent directive
                if (cleanedLine.startsWith('user-agent:')) {
                    currentUserAgent = cleanedLine.split(':')[1].trim();
                    isApplicableForUserAgent = currentUserAgent === '*' || currentUserAgent === 'my-bot';
                    return;
                }
    
                // If the rules are for applicable user-agent
                if (isApplicableForUserAgent) {
                    // Check for Disallow directive
                    if (cleanedLine.startsWith('disallow:')) {
                        const path = cleanedLine.split(':')[1].trim();
                        if (path) disallowRules.push(path);
                    }
    
                    // Check for Allow directive
                    if (cleanedLine.startsWith('allow:')) {
                        const path = cleanedLine.split(':')[1].trim();
                        if (path) allowRules.push(path);
                    }
                }
            });
    
            // Analyze the URL path against the robots.txt rules
            const urlPath = new URL(formattedUrl).pathname;
            let isDisallowed = disallowRules.some((rule) => urlPath.startsWith(rule));
            let isAllowed = allowRules.some((rule) => urlPath.startsWith(rule));
    
            // If explicitly allowed, override disallow
            if (isAllowed) {
                isDisallowed = false;
            }
    
            // Set the extraction status based on the rules
            if (isDisallowed) {
                setCrawlAllowed(false);
                setExtractionStatus('Looks like your website does not allow automatic extraction.');
            } else {
                setCrawlAllowed(true);
                setExtractionStatus('It looks like extraction is allowed by your website!');
            }
        } catch (error) {
            if (error instanceof TypeError) {
                // Handle invalid URL formats
                setCrawlAllowed(false);
                setExtractionStatus('The URL format is invalid. Please check and try again.');
            } else {
                setCrawlAllowed(false);
                setExtractionStatus('Could not fetch robots.txt or extraction may be restricted.');
            }
        } finally {
            setCheckingExtraction(false);
        }
    };

    // Debounce the website URL to wait for the user to stop typing
    useEffect(() => {
        const handler = setTimeout(() => {
            if (chatbotConfig.websiteUrl) {
                setDebouncedUrl(chatbotConfig.websiteUrl); // Set the debounced URL after 1 second of inactivity
            }
        }, 250);

        // Clear the timeout if the user is still typing (cleanup)
        return () => {
            clearTimeout(handler);
        };
    }, [chatbotConfig.websiteUrl]); // Run this effect when the websiteUrl changes

    // Trigger the robots.txt check when the debounced URL changes
    useEffect(() => {
        if (debouncedUrl) {
            checkWebsiteExtraction(debouncedUrl);
        }
    }, [debouncedUrl]); // Only trigger when the debounced URL changes

    useEffect(() => {
        if (crawlAllowed) {
            chatbotConfig.crawlAllowed = true;
        } else {
            chatbotConfig.crawlAllowed = false;
        }
    }, [chatbotConfig, crawlAllowed]);

    return (
        <div className="chatbot-form-container">
            <h2 className="form-title">Get Started with Your Custom Chatbot</h2>
            <form className="chatbot-form" onSubmit={handleSubmit}>
                {/* Company Name */}
                <div className="form-group">
                    <label htmlFor="company">
                        Company Name<span className="required">*</span>
                    </label>
                    <input
                        type="text"
                        id="company"
                        name="company"
                        value={chatbotConfig.company}
                        onChange={handleChange}
                        required
                        placeholder="Enter your company name"
                    />
                </div>

                {/* Business Description */}
                <div className="form-group">
                    <label htmlFor="businessDescription">
                        Describe Your Business in One Sentence<span className="required">*</span>
                    </label>
                    <input
                        type="text"
                        id="businessDescription"
                        name="businessDescription"
                        value={chatbotConfig.businessDescription}
                        onChange={handleChange}
                        required
                        placeholder="e.g., We provide eco-friendly packaging solutions."
                    />
                </div>

                {/* Chatbot Name */}
                <div className="form-group">
                    <label htmlFor="chatbotName">
                        Chatbot Name<span className="required">*</span>
                    </label>
                    <input
                        type="text"
                        id="chatbotName"
                        name="chatbotName"
                        value={chatbotConfig.chatbotName}
                        onChange={handleChange}
                        required
                        placeholder="e.g., EcoBot, SupportMate"
                    />
                </div>

                {/* Chatbot Type */}
                <div className="form-group">
                    <label htmlFor="chatbotType">
                        What Type of Chatbot Are You Making?<span className="required">*</span>
                    </label>
                    <select
                        id="chatbotType"
                        name="chatbotType"
                        value={chatbotConfig.chatbotType}
                        onChange={handleChange}
                        required
                    >
                        <option value="">-- Select Chatbot Type --</option>
                        <option value="service">Service</option>
                        <option value="technical support">Technical Support</option>
                        <option value="sales">Sales</option>
                        <option value="other">Other</option>
                    </select>
                </div>

                {/* Chatbot Tone */}
                <div className="form-group">
                    <label htmlFor="chatbotTone">
                        Chatbot Tone<span className="required">*</span>
                    </label>
                    <select
                        id="chatbotTone"
                        name="chatbotTone"
                        value={chatbotConfig.chatbotTone}
                        onChange={handleChange}
                        required
                    >
                        <option value="">-- Select Chatbot Tone --</option>
                        <option value="friendly">Friendly</option>
                        <option value="professional">Professional</option>
                        <option value="casual">Casual</option>
                        <option value="formal">Formal</option>
                        <option value="humorous">Humorous</option>
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="fallbackBehavior">
                        Fallback Behavior<span className="required">*</span>
                    </label>
                    <select
                        id="fallbackBehavior"
                        name="fallbackBehavior"
                        value={chatbotConfig.fallbackBehavior}
                        onChange={handleChange}
                    >
                        <option value="">-- Select Fallback Behavior --</option>
                        <option value="escalate">Escalate to Human Agent</option>
                        <option value="provide generic response">Provide Generic Response</option>
                        <option value="offer resources">Offer Help Resources (FAQs, Guides)</option>
                    </select>
                </div>

                {/* Response Conciseness */}
                <div className="form-group">
                    <label htmlFor="responseConciseness">
                        Response Conciseness<span className="required">*</span>
                    </label>
                    <select
                        id="responseConciseness"
                        name="responseConciseness"
                        value={chatbotConfig.responseConciseness}
                        onChange={handleChange}
                        required
                    >
                        <option value="">-- Select Conciseness Level --</option>
                        <option value="very_concise">Very Concise</option>
                        <option value="concise">Concise</option>
                        <option value="moderate">Moderate</option>
                        <option value="detailed">Detailed</option>
                        <option value="very_detailed">Very Detailed</option>
                    </select>
                </div>
                
                {/* Website URL for Extraction */}
                <div className="form-group">
                    <label htmlFor="websiteUrl">Company Website URL (for Information Extraction)</label>
                    <input
                        type="url"
                        id="websiteUrl"
                        name="websiteUrl"
                        value={chatbotConfig.websiteUrl}
                        onChange={handleChange}
                        placeholder="Enter your company website URL"
                    />
                    {/* Extraction feedback */}
                    {checkingExtraction && <p>Checking if extraction is allowed...</p>}
                    {extractionStatus && <p className={crawlAllowed ? 'crawl-allowed' : 'crawl-disallowed'}>{extractionStatus}</p>}
                </div>

                {/* Product Documentation Upload */}
                <div className="form-group">
                    <label htmlFor="productDocs">Upload Referencing Documents
                    </label>
                    <input
                        type="file"
                        id="productDocs"
                        name="productDocs"
                        accept="application/pdf"
                        multiple
                        onChange={handlePdfChange}
                    />
                    <div className="uploaded-files-list" style={{ marginTop: '10px' }}>
                        {pdfFiles.map((file, index) => (
                            <div key={index} className="uploaded-file-item" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <span>{file.name}</span>
                                <button type="button" onClick={() => handlePdfDelete(index)} className="delete-button">
                                    Delete
                                </button>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Submit Button */}
                <div className="form-group">
                    <button type="submit" className="submit-button">
                        Build My Chatbot
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ChatbotForm;