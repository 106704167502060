import "./Chat.css";
import React, { useState, useEffect, useRef, useCallback } from "react";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import Loading from './Loading';
import Preview from "./Preview";
import Tune from "./Tune";

const Chat = ({ fetchResponse, uploadFile, authToken, chatId, greetings, cachedMessages, isBuild, isTunning }) => {
    const [messages, setMessages] = useState([]);
    const [userInput, setUserInput] = useState("");
    const [processing, setProcessing] = useState(false);
    const [, setFile] = useState(null);
    const [score, setScore] = useState(-1);
    const [tuneStarted, setTuneStarted] = useState(false);
    const [finished, setFinished] = useState(false);

    useEffect(() => {
        if (cachedMessages.length > 0) {
            setMessages(cachedMessages);
        }
    }, [cachedMessages]);

    const messagesEndRef = useRef(null);
    const controllerRef = useRef(null);

    // const maxMessages = 50;
    const minLoadingTime = 500;
    const typingSpeed = 50;
    const Roles = {
        USER: 'user',
        BOT: 'bot',
        SYS: 'system',
        TDR: 'tender'
    };

    const sendScore = useCallback(async (s) => {
        if (s >= 4) return;

        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        controllerRef.current = new AbortController();

        const response = await fetch(`/api/v1/tune/${chatId}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: `Bearer ${authToken}`,
            },
            body: new URLSearchParams({
                inputs: `I give the response a ${s}/5 rating.`,
                score: s,
            }),
            signal: controllerRef.current.signal,
        });

        if (response.ok) {
            const data = await response.json();
            const msg = data.response;
            if (msg) {
                setMessages((prevMessages) => [
                    ...prevMessages,
                    { content: msg, role: Roles.TDR },
                ]);;
            }

            if (msg !== 'Could you provide a brief explanation for your rating?') {
                setScore(-1);
            }
        }
    }, [chatId, authToken, Roles.TDR]);

    const typeBotMessage = useCallback((fullMessage) => {
        const handleTune = (s) => {
            setMessages((prevMessages) => prevMessages.filter(
                (message) => message.role !== Roles.SYS
            ));
            sendScore(s);
        };

        setProcessing(true);

        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        controllerRef.current = new AbortController();

        const tokens = fullMessage.split(' ');
        if (tokens.length === 0) {
            setProcessing(false);
            return;
        }

        let currentTokenIndex = 0;
        let accumulatedMessage = '';

        const role = fullMessage === 'Thank you for your feedback. Please continue the conversation.' ? Roles.TDR : Roles.BOT;
        if (fullMessage === 'Thank you for trying out the bot builder. Since this feature is still in early development, I will not be able to save your bot :(') {
            setFinished(true);
            // fullMessage = 'Thank you for trying out the bot builder, since we are still in beta, your bot will not be saved :(';
        }

        const interval = setInterval(() => {
            accumulatedMessage += (currentTokenIndex > 0 ? ' ' : '') + tokens[currentTokenIndex];

            setMessages((prevMessages) => {
                if (prevMessages.length === 0) {
                    return [{ role: role, content: accumulatedMessage }];
                }
                const lastMessage = prevMessages[prevMessages.length - 1];
                const updatedMessages = [
                    ...prevMessages.slice(0, -1),
                    { ...lastMessage, content: accumulatedMessage, role: role },
                ];
                return updatedMessages;
            });

            currentTokenIndex++;
            if (currentTokenIndex === tokens.length) {
                clearInterval(interval);
                setProcessing(false);

                if (isTunning && tuneStarted && !finished &&
                    fullMessage !== 'Could you provide a brief explanation for your rating?' &&
                    fullMessage !== 'Thank you for your feedback. Please continue the conversation.'
                ) {
                    setMessages((prevMessages) => [
                        ...prevMessages,
                        { content: <Tune setScore={setScore} handleTune={handleTune} />, role: Roles.SYS },
                    ]);
                } else if (isTunning && !tuneStarted && !finished) {
                    setTuneStarted(true);
                }
            }
        }, typingSpeed);
    }, [Roles.BOT, Roles.SYS, Roles.TDR, finished, isTunning, sendScore, tuneStarted]);

    useEffect(() => {
        if (isTunning && messages.length === 0) {
            typeBotMessage("Hi, I'm ready to learn from you. Please interact with me like you would with an end user to help me improve.");
            return;
        }

        const cache = isBuild ? 'builds' : 'greets';
        const cached = sessionStorage.getItem(cache);
        if (greetings && cached === null && messages.length === 0) {
            typeBotMessage(greetings);
        }
    }, [greetings, isBuild, isTunning, messages, typeBotMessage]);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !processing) {
            e.preventDefault();
            handleSubmit();
        }
    };

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const handleSubmit = async () => {
        if (!userInput) {
            return;
        }

        setProcessing(true);

        if (controllerRef.current) {
            controllerRef.current.abort();
        }

        const controller = new AbortController();
        controllerRef.current = controller;

        // Add the user message and a loading bot message
        setMessages((prevMessages) => [
            ...prevMessages,
            { content: userInput, role: Roles.USER },
            { content: <Loading />, role: Roles.BOT } // Add the Loading component here
        ]);

        setUserInput("");
        const [response] = await Promise.all([
            fetchResponse(authToken, userInput, chatId, controller, score),
            delay(minLoadingTime),
        ]);

        // Type the bot message with the response
        typeBotMessage(response);
        setProcessing(false);
    };

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [messages, chatId, isBuild, processing, Roles.BOT, isTunning]);

    const handleUpload = async (event) => {
        const uploadedFile = event.target.files[0];
        if (!uploadedFile || !isBuild) return;

        setFile(uploadedFile);
        setProcessing(true);

        const formData = new FormData();
        formData.append('file', uploadedFile);

        if (controllerRef.current) {
            controllerRef.current.abort();
        }

        const controller = new AbortController();
        controllerRef.current = controller;

        setMessages((prevMessages) => [
            ...prevMessages,
            { content: <Loading />, role: Roles.USER },
        ]);

        const [response] = await Promise.all([
            uploadFile(authToken, formData, controller),
            delay(minLoadingTime),
        ]);

        if (!response.ok) {
            typeBotMessage(response.message);
        } else {
            const fileName = uploadedFile.name;
            const fileExt = uploadedFile.name.split('.').pop();
            setMessages((prevMessages) => [
                ...prevMessages.slice(0, -1),
                { content: <Preview fileName={fileName} fileExt={fileExt} />, role: Roles.USER },
            ]);
        }
        setProcessing(false);
        setFile(null);
    };

    const isTuneActive = () => {
        if (messages.length === 0) return false;
        const lastMessage = messages[messages.length - 1];
        return lastMessage.role === Roles.SYS && lastMessage.content.type === Tune;
    };

    return (
        <div className="inner-chat-container">
            <div className="chat-messages">
                {messages.map((message, index) => (
                    <div
                        key={index}
                        className={`message ${message.role === Roles.BOT
                                ? 'bot-message'
                                : message.role === Roles.SYS
                                    ? 'system-message'
                                    : message.role === Roles.TDR
                                        ? 'tender-message'
                                        : 'user-message'
                            }`}
                    >
                        {typeof message.content === 'string' ? (
                            <ReactMarkdown
                                remarkPlugins={[remarkGfm]}
                                components={{
                                    code({ node, inline, className, children, ...props }) {
                                        const match = /language-(\w+)/.exec(className || '');
                                        return !inline && match ? (
                                            <SyntaxHighlighter
                                                style={oneDark}
                                                language={match[1]}
                                                PreTag="div"
                                                {...props}
                                            >
                                                {String(children).replace(/\n$/, '')}
                                            </SyntaxHighlighter>
                                        ) : (
                                            <code className={className} {...props}>
                                                {children}
                                            </code>
                                        );
                                    },
                                }}
                            >
                                {message.content}
                            </ReactMarkdown>
                        ) : (
                            message.content
                        )}
                    </div>
                ))}
                <div ref={messagesEndRef} />
            </div>
            <div className={`memo ${isBuild ? 'active' : ''}`}>
                This is an experimental feature
            </div>
            <div className="chat-input-container">
                {isBuild && (
                    <>
                        <input
                            type="file"
                            id="fileUpload"
                            hidden
                            onChange={handleUpload}
                            disabled={processing || isTuneActive() || finished}
                            aria-label="Attach a file"
                        />
                        <button
                            onClick={() => document.getElementById('fileUpload').click()}
                            disabled={processing || isTuneActive() || finished}
                            aria-label="Attach"
                        >
                            <img className="attach-icon" src="/paper-clip.svg" alt="attach" />
                        </button>
                    </>
                )}
                <input
                    type="text"
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    onKeyDown={handleKeyPress}
                    className="chat-input"
                    placeholder="Type here to chat"
                    disabled={processing || isTuneActive() || finished}
                />
                <button type="submit" disabled={processing || isTuneActive() || finished}>
                    <img className="send-icon" src="/paper-plane.svg" alt="send" />
                </button>
            </div>
        </div>
    );
};

export default Chat;