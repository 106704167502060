import "./Personal.css";
import React, { useState } from "react";
import { updateProfile } from "firebase/auth";
import { db } from "../../Firebase"; // Import Firebase database instance
import { doc, setDoc } from "firebase/firestore";
import ISO6391 from 'iso-639-1';
import { FaCamera } from 'react-icons/fa';  // Import camera icon
import { getNames as getCountryNames } from 'country-list';

const industries = [
    'Technology',
    'Healthcare',
    'Finance',
    'Education',
    'Manufacturing',
    'Retail',
    'Hospitality',
    'Transportation',
    'Real Estate',
    'Energy',
    'Telecommunications',
    'Public Sector',
    'Entertainment',
    'Agriculture',
    'Construction'
];

const Personal = ({ user }) => {
    const isThirdPartyUser = user?.providerData?.some(provider => provider.providerId !== 'password');
    const [editableField, setEditableField] = useState(null);
    const [formData, setFormData] = useState({
        name: user?.displayName || '',
        country: 'United States',
        language: 'English (US) - English',
        occupation: ''
    });
    const [showSaveButton, setShowSaveButton] = useState(false);

    const handleFieldClick = (field) => {
        if (!isThirdPartyUser || field !== 'name') {
            setEditableField(field);
        }
    };

    const handleInputChange = (field, value) => {
        setFormData({ ...formData, [field]: value });
        setShowSaveButton(true);
    };

    const handleSaveChanges = async () => {
        try {
            if (!isThirdPartyUser) {
                await updateProfile(user, { displayName: formData.name });
            }
            const userDocRef = doc(db, "users", user.uid);
            await setDoc(userDocRef, {
                country: formData.country,
                language: formData.language,
                occupation: formData.occupation
            }, { merge: true });
            setShowSaveButton(false);
        } catch (error) {
            console.error("Error saving changes: ", error);
        }
    };

    const renderField = (field, label, value, options) => (
        <div className="personal-card" onClick={() => handleFieldClick(field)}>
            <p><strong>{label}</strong></p>
            {editableField === field ? (
                field === 'name' ? (
                    <input
                        className="inputt"
                        type="text"
                        value={formData[field]}
                        placeholder={`Enter your ${label.toLowerCase()}`}
                        onChange={(e) => handleInputChange(field, e.target.value)}
                        onBlur={() => setEditableField(null)}
                    />
                ) : (
                    <select
                        value={formData[field]}
                        onBlur={() => setEditableField(null)}
                        onChange={(e) => handleInputChange(field, e.target.value)}
                        className="styled-dropdown"
                    >
                        <option value="">Select your {label.toLowerCase()}</option>
                        {options.map((option, index) => (
                            <option key={index} value={option}>{option}</option>
                        ))}
                    </select>
                )
            ) : (
                <p>{formData[field] || `Enter your ${label.toLowerCase()}`}</p>
            )}
        </div>
    );

    return (
        <div className="personal-container">
            <div className="personal-content">
                <div className="user-info">
                    <div className="profile-photo-container">
                        {user?.photoURL ? (
                            <img className="profile-photo" src={user.photoURL} alt="Profile" />
                        ) : (
                            <div className="profile-photo-placeholder">
                                <FaCamera className="camera-icon" />
                            </div>
                        )}
                    </div>
                    <div className="user-details">
                        <h3>{user ? user.displayName : 'Guest'}</h3>
                        <p>{user?.email}</p>
                    </div>
                </div>
                <div className="personal-cards">
                    {renderField('name', 'Name', formData.name, [])}
                    {renderField('country', 'Country/Region', formData.country, getCountryNames())}
                    {renderField('language', 'Language', formData.language, ISO6391.getAllNames())}
                    {renderField('occupation', 'Occupation/Industry', formData.occupation, industries)}
                </div>
            </div>
            {showSaveButton && (
                <button className="save-button" onClick={handleSaveChanges}>Save Changes</button>
            )}
        </div>
    );
}

export default Personal;
